
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import {
    defineComponent,
    ref,
    onMounted,
    reactive,
    toRefs,
    computed,
} from 'vue';
import { qmOptions } from '@/views/SalesReporting/type';
import { message, TreeSelect } from 'ant-design-vue';
import { useStore } from 'vuex';
import { findBrandByBu } from '@/API/salesReporting';
import { getFindByGroupCode } from '@/API/checking/SICCOCheckingDetail';
import { getPenetrationTable,getOptionPackage,getPDI,getSSSC } from '@/API/salesReporting';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

interface SearchDataType {
    bu: string | undefined;
    year: string | undefined;
    brand: string | undefined;
    reportContent: string | undefined;
    claimBase: string | undefined;
    claimOption: string | undefined;
    codeNo: Array<string>;
}

export default defineComponent({
    setup() {
        const codeNoList = ref<any[]>([]);
        const arr= ['SSSC', 'Option Package', 'PDI'];
        const { state } = useStore();
        const buList = computed(() => state.user.buList);
        const downloadType = ref(2);
        const downloadTypeVisible = ref(false);
        //当前bu的所有Brand数组
        const brandList = ref<any[]>([]);
        //用于筛选的brand数组
        const brandArray = ref<any[]>([]);
        const searchData = reactive<SearchDataType>({
            bu: undefined,
            brand: undefined,
            year: undefined,
            reportContent: undefined,
            claimBase: undefined,
            claimOption: undefined,
            codeNo:[]
        });
        const reportContentList = ref<any[]>([]);
        const claimBaseList = ref<any[]>([]);
        const optionList = ref<any[]>([]);

        const columns = ref<any[]>([]);
        const dataSource = ref<any[]>([]);
        let isTable3 = false;
        const month12 = [
            {
                month: '01',
                value: 'oneMonth',
                subTitle: '',
            },
            {
                month: '02',
                value: 'twoMonth',
                subTitle: '',
            },
            {
                month: '03',
                value: 'threeMonth',
                subTitle: '',
            },
            {
                month: 'Q1',
                value: 'oneQuarter',
                subTitle: 'Q1Total',
            },
            {
                month: '04',
                value: 'fourMonth',
                subTitle: '',
            },
            {
                month: '05',
                value: 'fiveMonth',
                subTitle: '',
            },
            {
                month: '06',
                value: 'sixMonth',
                subTitle: '',
            },
            {
                month: 'Q2',
                value: 'twoQuarter',
                subTitle: 'Q2Total',
            },
            {
                month: '07',
                value: 'twelveMont',
                subTitle: '',
            },
            {
                month: '08',
                value: 'eightMonth',
                subTitle: '',
            },
            {
                month: '09',
                value: 'nineMonth',
                subTitle: '',
            },
            {
                month: 'Q3',
                value: 'threeQuarter',
                subTitle: 'Q3Total',
            },
            {
                month: '10',
                value: 'tenMonth',
                subTitle: '',
            },
            {
                month: '11',
                value: 'elevenMonth',
                subTitle: '',
            },
            {
                month: '12',
                value: 'twelveMonth',
                subTitle: '',
            },
            {
                month: 'Q4',
                value: 'fourQuarter',
                subTitle: 'Q4Total',
            },
            {
                month: 'TYDTotal',
                value: 'total',
                subTitle: 'TYDTotal',
            },
        ];

        const month3 = [
            {
                month: '01',
                value: '01',
                subTitle: '',
            },
            {
                month: '02',
                value: '02',
                subTitle: '',
            },
            {
                month: '03',
                value: '03',
                subTitle: '',
            },
            {
                month: 'Q1',
                value: 'Q1',
                subTitle: 'Q1Total',
            },
            {
                month: '04',
                value: '04',
                subTitle: '',
            },
            {
                month: '05',
                value: '05',
                subTitle: '',
            },
            {
                month: '06',
                value: '06',
                subTitle: '',
            },
            {
                month: 'Q2',
                value: 'Q2',
                subTitle: 'Q2Total',
            },
            {
                month: '07',
                value: '07',
                subTitle: '',
            },
            {
                month: '08',
                value: '08',
                subTitle: '',
            },
            {
                month: '09',
                value: '09',
                subTitle: '',
            },
            {
                month: 'Q3',
                value: 'Q3',
                subTitle: 'Q3Total',
            },
            {
                month: '10',
                value: '10',
                subTitle: '',
            },
            {
                month: '11',
                value: '11',
                subTitle: '',
            },
            {
                month: '12',
                value: '12',
                subTitle: '',
            },
            {
                month: 'Q4',
                value: 'Q4',
                subTitle: 'Q4Total',
            },
            {
                month: 'TYDTotal',
                value: 'TYDTotal',
                subTitle: 'TYDTotal',
            },
        ];

        let monthArray = month12

        let isPer = "";
        let isVolume = true;
        let reportContent = ""

        const assemblyColumns = (isMonth: boolean)=>{
            const array = new Array<any>();
            monthArray = month12;
            isTable3 = false;
            switch (reportContent) {
                case "Trade-in/Repurchase":
                    array.push({
                        head: 'Repurchase',
                        value: 'Repurchase' + isPer,
                    })
                    array.push({
                        head: 'MBTrade-in',
                        value: 'MbTradeIn' + isPer,
                    })
                    array.push({
                        head: 'OthersTrade-in',
                        value: 'OthersTradeIn' + isPer,
                    })
                    if (!isMonth){
                        array.push({
                            head: 'Repurchase&Trade-inTotal',
                            value: 'Rmo'+ isPer,
                        })

                    }
                    if (!isPer && isVolume){
                        array.push({
                            head: 'RT',
                            value: 'Rt',
                        })
                    }
                    break;
                case "MB Collections & Accessories":
                case "3rd party accessories":
                case "Insurance":
                    array.push({
                        head: reportContent,
                        value: 'SiOffer' + isPer,
                    })
                    if (!isPer && isVolume){
                        array.push({
                            head: 'RT',
                            value: 'Rt',
                        })
                    }
                    
                    break;
                case "SSSC":
                case "Option Package":
                case "PDI":
                    monthArray = month3;
                    isTable3 = true;
                    for (let i=0;i<searchData.codeNo.length;i++){
                        array.push({
                            head: searchData.codeNo[i],
                            value: searchData.codeNo[i]+ isPer,
                        })

                    }
                    if (!isPer && isVolume){
                        array.push({
                            head: 'RT',
                            value: 'Rt',
                        })
                    }
                    
                    break;
            } 
            return array
        }

        //根据赛选条件，设置表头
        const createColumns = (year: string) => {
            //判断是不是查询百分比
            isVolume = false;
            for (let i=0;i<optionList.value.length;i++){
                if (optionList.value[i].itemCode === searchData.claimOption){
                    if (optionList.value[i].itemNameEn === 'Percentage'){
                        isPer = "Per"
                        
                    } else {
                        isPer = ""
                    }
                    if (optionList.value[i].itemNameEn === 'Volume'){
                        isVolume = true
                    }
                }
            }
            //获取reportContent的名称，用于判断
            for (let i=0;i<reportContentList.value.length;i++){
                if (reportContentList.value[i].id === searchData.reportContent){
                    reportContent = reportContentList.value[i].itemNameEn
                }
            }
            const monthCondition = assemblyColumns(true);
            const quarterCondition = assemblyColumns(false);
            const totalCondition = assemblyColumns(false);

            const columnsArray = [];
            columnsArray.push({
                title: 'TypeClass',
                dataIndex: isTable3?'type_class':'typeClass',
                key: isTable3?'type_class':'typeClass',
                width: 200,
                fixed: 'left',
            });
            columnsArray.push({
                title: 'Model',
                dataIndex: 'model',
                key: 'model',
                width: 300,
                fixed: 'left',
            });
            for (let i = 0; i < monthArray.length; i++) {
                const title3: Array<any> = [];
                let array = [];
                if (i === 16) {
                    array = totalCondition;
                } else if ((i+1) % 4 === 0 && i !== 0) {
                    array = quarterCondition;
                } else {
                    array = monthCondition;
                }


                array.forEach((item) => {
                    title3.push({
                        title: item.head,
                        dataIndex: monthArray[i].value + item.value,
                        key: monthArray[i].value + item.value,
                        width: 200,
                    });
                });
                const title2 = {
                    title: monthArray[i].subTitle,
                    children: title3,
                };
                const title1 = {
                    title: year + monthArray[i].month,
                    children: [title2],
                };
                columnsArray.push(title1);
            }
            return columnsArray;
        };

        const getParams = (isDownload: boolean) => {
            let reportContent = null;
            for (let i = 0; i < reportContentList.value.length; i++) {
                if (
                    reportContentList.value[i].id === searchData.reportContent
                ) {
                    reportContent = reportContentList.value[i];
                }
            }

            let buName = null;
            for (let i = 0; i < buList.value.length; i++) {
                if (buList.value[i].id === searchData.bu) {
                    buName = buList.value[i].nameEn;
                }
            }

            const params = {
                bus: [buName],
                brands: (searchData.brand==='All' || !searchData.brand)?[]:[searchData.brand],
                year: searchData.year,
                claimBase: searchData.claimBase, 
                option: searchData.claimOption,
                codeNo:searchData.codeNo,
                reportContent:{
                    id: reportContent.id,
                    groupNameEn: reportContent.groupNameEn,
                    groupNameCn: reportContent.groupNameCn,
                    groupCode: reportContent.groupCode,
                    itemNameEn: reportContent.itemNameEn,
                    itemCode: reportContent.itemCode,
                    itemCodes: reportContent.itemCodes,
                }
                // 'reportContent.id': reportContent.id,
                // 'reportContent.groupNameEn': reportContent.groupNameEn,
                // 'reportContent.groupNameCn': reportContent.groupNameCn,
                // 'reportContent.groupCode': reportContent.groupCode,
                // 'reportContent.itemNameEn': reportContent.itemNameEn,
                // 'reportContent.itemCode': reportContent.itemCode,
                // 'reportContent.itemCodes': reportContent.itemCodes,
            };
            console.log(params)

            return params;
        };

        //初始化，查询参数，并查询数据
        const firstBu = ref('');

        const getReportContent = getFindByGroupCode({
            groupCode: 'penetration_report_content',
        });
        const getClaimBase = getFindByGroupCode({
            groupCode: 'penetration_claim_base',
        });
        const getOption = getFindByGroupCode({
            groupCode: 'penetration_option',
        });

        const initCondition = (array: Array<string>) => {
            Promise.all([
                getReportContent,
                getClaimBase,
                getOption,
                findBrandByBu({ buIds: array }),
            ]).then((result) => {
                //设置3个查询下拉框
                reportContentList.value = result[0];
                console.log(result[0])
                claimBaseList.value = result[1];
                optionList.value = result[2];
                //赋予初始值
                searchData.reportContent = reportContentList.value[0].id;
                searchData.claimBase = claimBaseList.value[0].itemCode;
                searchData.claimOption = optionList.value[0].itemCode;
                //根据bu，获取brand
                brandList.value = result[3];
                searchData.bu = firstBu.value;
                searchData.year = moment().format('YYYY');
                for (let i = 0; i < brandList.value.length; i++) {
                    if (searchData.bu == brandList.value[i].buId) {
                        brandArray.value = JSON.parse(JSON.stringify(brandList.value[i].brandList))
                    }
                }
                brandArray.value.push({
                    brandNameCn: "All",
                    brandNameEn: "All",
                    id: -1
                })
                searchData.brand = 'All';

                //获取查询参数，查询表格数据
                const params = getParams(false);
                getPenetrationTable(params).then((res) => {
                    console.log(res);
                    columns.value = createColumns(searchData.year as string);
                    console.log(columns.value)
                    dataSource.value = res;
                });
            });
        };

        const checkCondition=()=>{
            if (!(searchData.bu && searchData.year && searchData.reportContent && searchData.claimBase && searchData.claimOption)){
                return false;
            }
            let name = ""
            for (let i=0;i<reportContentList.value.length;i++){
                if (reportContentList.value[i].id === searchData.reportContent){
                    name = reportContentList.value[i].itemNameEn
                }
            }

            if ((arr.includes(name))){
                if (searchData.codeNo.length == 0){
                    return false
                }
            }

            return true;
            
        }

        const getTableData = ()=>{
            //获取查询参数，查询表格数据
            if (!checkCondition()){
                message.error('请设置查询条件')
                return
            }
            
            const params = getParams(false);
            dataSource.value = [];
            getPenetrationTable(params).then((res) => {
                console.log(res);
                columns.value = createColumns(searchData.year as string);
                console.log(columns.value)
                dataSource.value = res;
            });
        }

        const init = () => {
            firstBu.value = buList.value.length ? buList.value[0].id : '';
            const buIdArray = [];
            for (let i = 0; i < buList.value.length; i++) {
                buIdArray.push(buList.value[i].id);
                if (buList.value[i].nameEn === 'MBPC') {
                    firstBu.value = buList.value[i].id;
                }
            }
            initCondition(buIdArray);
        };

        onMounted(() => {
            init();
        });

                //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const isShow = ref(true);

        const checkReportContent = (value: string)=>{
            searchData.codeNo = [];
            let itemNameEn = null
            for (let i=0;i<reportContentList.value.length;i++){
                if (value === reportContentList.value[i].id){
                    isShow.value = !(arr.includes(reportContentList.value[i].itemNameEn))
                    itemNameEn = reportContentList.value[i].itemNameEn;
                }
            }
            if (itemNameEn === 'SSSC'){
                getSSSC({bu:searchData.bu}).then((res)=>{
                    console.log(res)
                    codeNoList.value = []
                    for (let i=0;i<res.length;i++){
                        codeNoList.value.push(res[i].oasisName)
                    }
                    
                })
            }   
            if (itemNameEn === 'Option Package'){
                //
                getOptionPackage(getParams(false)).then((res)=>{
                    console.log(res)
                    codeNoList.value = []
                })
            }
            if (itemNameEn === 'PDI'){
                getPDI({bu:searchData.bu}).then((res)=>{
                    console.log(res)
                    codeNoList.value = []
                    for (let i=0;i<res.length;i++){
                        codeNoList.value.push(res[i].pdiCode)
                    }
                })
            }
        }

        const reset = ()=>{
            searchData.year = moment().format('YYYY');
            searchData.bu = firstBu.value;
            searchData.brand = undefined;
            searchData.reportContent = undefined;
            searchData.codeNo = [];
            searchData.claimBase = undefined
            searchData.claimOption = undefined;
        }

        const download = ()=>{
            if (!checkCondition()){
                message.error('请设置查询条件')
                return
            }
            const api = 'rpapi/penetration/report/export'
            const par = {
                url: api,
                method: "post",
                params: getParams(true),
            };
            downloadFile(par, "application/vnd-excel; char-set=UTF-8").then((res) => {
                console.log(res);
                }
            );
        }

        const getBrand = (buid: string)=>{
            searchData.codeNo = [];
            searchData.brand = undefined;
            searchData.reportContent = undefined;
            for (let i = 0; i < brandList.value.length; i++) {
                if (searchData.bu == brandList.value[i].buId) {
                    brandArray.value = JSON.parse(JSON.stringify(brandList.value[i].brandList));

                }
            }
            brandArray.value.push({
                brandNameCn: "All",
                brandNameEn: "All",
                id: -1
            })
        }

        return {
            getBrand,
            codeNoList,
            download,
            reset,
            checkReportContent,
            isShow,
            getTableData,
            openProgramPeriodYear,
            handlerPanelProgramPeriodChange,
            handlerOpenProgramPeriodChange,
            dataSource,
            columns,
            brandArray,
            reportContentList,
            claimBaseList,
            optionList,
            buList,
            downloadTypeVisible,
            SHOW_PARENT,
            downloadType,
            ...toRefs(searchData),
        };
    },
});
